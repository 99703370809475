/*
*
* File JS componente Scroll Cta
*
*/

// IMPORT LIBRERIE ESTERNE
//--------------------------------------------------------------------//



//--------------------------------------------------------------------//

// IMPORT SCSS PER LA COMPILAZIONE
//--------------------------------------------------------------------//

import './coming-soon.scss';

//--------------------------------------------------------------------//


// Funzioni per il funzionamento della mappa Coming
//--------------------------------------------------------------------// 



const comingSoon = () => {
    
    const comingSoon = document.querySelector(".coming-soon");
    if (!comingSoon) return;

    

    const nations = comingSoon.querySelectorAll(".coming-soon__nation");
    const wordsNation = comingSoon.querySelectorAll(".coming-soon__words")

    nations.forEach((nation) => {
        nation.addEventListener("click", (e) => {
            e.preventDefault();
            activeCurrentNation(nation);
    showCurrentWords(nation);
        });
    });

    //Active Current Nation
    function activeCurrentNation(currentNation) {
        nations.forEach((nation) => {
            nation.classList.remove("coming-soon__nation--active");
        });
        currentNation.classList.toggle("coming-soon__nation--active");
    }


    //show Current Words
    function showCurrentWords(nation){
        const id = nation.getAttribute('data-map-id')
        const currentWord = comingSoon.querySelector(`[data-words-id=${id}]`)
        activeCurrentWords(currentWord);
    }

    //Active Current Words
    function activeCurrentWords(currentWord){
        wordsNation.forEach(wordNation => {
        wordNation.classList.remove('coming-soon__words--active')
        });
        currentWord.classList.toggle('coming-soon__words--active')
    }
    
}

export default comingSoon;


//--------------------------------------------------------------------//